<template>
    <section class="profile">
    <div class="dash-container">
        <h1>
        PROFILE <span v-if="this.vip == true"><button alt="This is an account with no contact limits." type="disabled" class="vip">VIP</button></span>
        </h1>
        
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        
            <!-- User Info -->
            
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <table :class="basicclass">
                    <tr>
                        <td class="profile-header">
                            <p class="org-description is-size-5"></p>
                        </td>
                    </tr>
                    <tr>
                        <td class="profile-content has-text-centered">
                            <table class="field-table mail-table">
                                <tr>
                                    <td>
                                        <ValidationProvider rules="required|email" name="Email" v-slot="{ errors, valid }">
                                            <b-field label="Email Address" label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input readonly="readonly" type="email" maxlength="50" onkeyup='this.value = this.value.toLowerCase();' v-model="email">
                                                </b-input> 
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </table>
                            <table class="field-table">
                                <tr>
                                    <td>
                                        <ValidationProvider rules="required|min:2|max:20" name="First" v-slot="{ errors, valid }">
                                            <b-field min-length="2" max-length="20" label="First Name" label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input v-model="first"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider rules="required|min:2|max:20" name="Last" v-slot="{ errors, valid }">
                                            <b-field min-length="2" max-length="20" label="Last Name" label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input v-model="last"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                            </table>
                            
                            <button class="button submit is-primary is-outlined" @click="handleSubmit(changeName)">
                                <span>Save</span>
                            </button>
                            <div v-if="nmsg" class="is-danger btn-msg" role="alert">{{nmsg}}</div>
                        </td>
                    </tr>
                </table>
            </ValidationObserver>

            <!-- Password -->
              
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <table :class="basicclass">
                <tr>
                    <td class="profile-header">
                        <p class="org-description is-size-5"></p>
                    </td>
                </tr>
                <tr>
                    <td class="profile-content has-text-centered">
                        <table class="field-table">
                            <tr>
                                <td class="pass">
                                    <form>
                                    <ValidationProvider :rules="{ regex: /(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/ }" vid="password" name="Password" v-slot="{ errors, valid }">
                                        <b-field label="Password" label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                            <b-input min-length="8" type="password" v-model="password" password-reveal >
                                            </b-input>
                                        </b-field>
                                    </ValidationProvider>
                                    </form>
                                </td>
                                <td class="pass">
                                    <form>
                                    <ValidationProvider rules="required|confirmed:password" vid="Confirmation" name="Password Confirmation" v-slot="{ errors, valid }">
                                        <b-field label="Confirm Password" label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                            <b-input type="password" v-model="confirmation" password-reveal >
                                            </b-input>
                                        </b-field>
                                    </ValidationProvider>
                                    </form>
                                </td>
                                <td class="pass">
                                    <button class="button change is-primary is-outlined" @click="handleSubmit(changePass)">
                                            <span>Change</span>
                                    </button>
                                    <div v-if="pamsg" class="is-danger btn-msg" role="alert">{{pamsg}}</div>
                                </td>
                            </tr>
                                
                        </table>
                    </td>
                </tr>
              </table>
            </ValidationObserver>

            <!-- Plan -->

            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <table :class="planclass">
                <tr>
                    <td class="profile-header">
                        <p class="org-description is-size-5"></p>
                    </td>
                </tr>
                <tr>
                    <td class="profile-content has-text-centered">
                        <table class="field-table">
                            <tr>
                                <td class="profile-plan">
                                    <ValidationProvider rules="required" name="Plan" v-slot="{ errors, valid }">
                                        <b-field class="profile-plan-select" label="Select Plan" label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                            <b-select v-model="plan">
                                                <option class="option" value="0">Free</option>
                                                <option class="option" value="1">Standard</option>
                                                <option class="option" value="2">Pro</option>
                                            </b-select>
                                            <button id="plan-change-btn" class="button plan-change is-primary is-outlined" @click="handleSubmit(changePlan)">
                                                <span>Change</span>
                                            </button>
                                            <span class="change-instruct" v-if='change != "00"'><b><--- Click change to switch plan</b></span>
                                        </b-field>
                                        <div v-if="plmsg" class="done btn-msg" role="alert">{{plmsg}}</div>
                                        <div v-if="plemsg" class="failed btn-msg" role="alert">{{plemsg}}</div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <td class="disclaimer">
                                    <br>
                                    <table class="profile-price-table">
                                        <tr>
                                            <td class="ppt-header">
                                                <b>FREE</b> (3 Contacts)
                                            </td>
                                            <td class="ppt-header">
                                                <b>STANDARD</b> (100 Contacts)
                                            </td>
                                            <td class="ppt-header">
                                                <b>PRO</b> (1000 Contacts)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="ppt-price">
                                                US$0/year
                                            </td>
                                            <td class="ppt-price">
                                                US$12/year
                                            </td>
                                            <td class="ppt-price">
                                                US$24/year
                                            </td>
                                        </tr>
                                    </table>
                                    <p class="small-print-end">Plan changes will take effect immediately and the new annual rate will be charged along with the prorated charges from your current plan. For more information on what the prices are per plan, visit our <router-link :to='{ path: "/pricing"}'>pricing page</router-link>.</p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
              </table>
            </ValidationObserver>
        </div>
    </section>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
    props: {
        change: {
            type: String,
            default: ""
        },
        payment: {
            type: String,
            default: ""
        }
    },
    components: {
            ValidationObserver,
            ValidationProvider
    },
    data() {
      return {
        email: this.$store.state.auth.user.account.email,
        password: "",
        confirmation: "",
        first: this.$store.state.auth.user.account.first,
        last: this.$store.state.auth.user.account.last,
        plan: this.$store.state.auth.user.account.plan,
        oplan: this.$store.state.auth.user.account.plan,
        nmsg: "",
        pamsg: "",
        plmsg: "",
        plemsg: "",
        basicclass: "profile-form has-text-centered",
        planclass: "profile-form has-text-centered",
        loading: false
      }
    },
    computed: {
        vip() {
            if (this.$store.state.auth.user.account.vip == true) {
                return true;
            } else {
                return false;
            }
        }
    },
    created() {
        if (this.payment != "") {
            if (this.payment == "paid") {
                this.plmsg = "Successfully changed!";
                this.payment = "";
            } else if (this.payment == "failed") {
                this.plemsg = "Change failed...";
                this.payment = "";
            } else {
                this.changePlan();
            }
        }

        if (this.change != "00") {
                this.plan = this.change;
                this.basicclass = "profile-form has-text-centered filtered"
                this.planclass = "profile-form has-text-centered plan-table"
        }
    },
    methods: {
        changeName() {
            this.nmsg = "";
            requestAnimationFrame(() => {
                var api = process.env.VUE_APP_API;
                console.log("Attempting to change name for: " + this.email + " via " + api)
                this.loading = true;
                if (this.first != this.$store.state.auth.user.account.first) {
                    if (this.first && api) {
                        this.$store.dispatch('auth/change_first', this.first).then(
                        (response) => {
                                console.log("if-first: ");
                                console.log(response.status);
                                if (response.status == true) {
                                    if (this.last == this.$store.state.auth.user.account.last) {
                                        this.nmsg = "Successfully changed!";
                                    }
                                } else {
                                    this.nmsg = "Unable to change name...";
                                }
                            },
                            error => {
                                console.log(error);
                                this.nmsg = "Couldn't connect to server..."
                            }
                        );
                    }
                }
                if (this.last != this.$store.state.auth.user.account.last) {
                    if (this.last && api) {
                        this.$store.dispatch('auth/change_last', this.last).then(
                        (response) => {
                                console.log("if-last: ");
                                console.log(response.status);
                                if (response.status == true) {
                                    this.nmsg = "Successfully changed!"
                                } else {
                                    this.nmsg = "Unable to change name...";
                                }
                            },
                            error => {
                                console.log(error);
                                this.nmsg = "Couldn't connect to server...";
                            }
                        );
                    }
                }
            });
            this.loading = false;
        },
        changePass() {
            this.pamsg = "";
            requestAnimationFrame(() => {
                var api = process.env.VUE_APP_API;
                console.log("Attempting to change password for: " + this.email + " via " + api)
                this.loading = true;
                
                if (this.password && api) {
                    this.$store.dispatch('auth/change_pass', this.password).then(
                    (response) => {
                            console.log("if-first: ");
                            console.log(response.status);
                            if (response.status == true) {
                                this.pamsg = "Successfully changed!";
                            } else {
                                this.pamsg = "Unable to change name...";
                            }
                        },
                        error => {
                            console.log(error);
                            this.pmsg = "Couldn't connect to server..."
                        }
                    );
                }
            });
            this.loading = false;    
        },
        changePlan() {
            this.load();
            this.plmsg = "";
            requestAnimationFrame(() => {
                console.log("Plan: " + this.plan);
                console.log("Payment: " + this.payment);
                if (this.payment == "") {
                    console.log("PAYMENT TEST:");
                    console.log("Plan: " + this.plan);
                    console.log("Old Plan: " + this.oplan);
                    if (this.plan == "1" && this.oplan == "0" || this.plan == "2" && this.oplan == "0") {
                        var subid;
                        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.state.auth.user.account.token;
                        axios.get(
                            process.env.VUE_APP_API + "/v1/payment/check",
                            { useCredentials: true }
                        )
                        .then((res) => {
                            console.log("CheckSubId Results:");
                            console.log(res);
                            if (res.data.data.status == false) {
                                subid = false;
                            } else {
                                subid = true;
                            }
                            console.log("SubID: " + subid);
                            if (subid == true) {
                                this.changePlanNow();
                            } else {
                                this.payNow();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.plmsg = "Server Error"
                            subid = false;
                            this.plmsg = "Server Error"
                        });
                    } else {
                        this.changePlanNow();
                    }
                } else {
                    this.changePlanNow();
                }
            });
        },
        payNow() {
            console.log("Getting token...");
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.state.auth.user.account.token;
            axios.post(
                process.env.VUE_APP_API + "/v1/payment/get",
                { plan: this.plan },
                { useCredentials: true }
            )
            .then((res) => {
                console.log(res);
                if (res.data.status == true) {
                    console.log(res.data.data);
                    this.$router.push({ path: "/payment/1/"+this.plan+ "/" + this.email + "/" + res.data.data })
                } else {
                    this.plemsg = "Can't initiate payment redirect..."
                }
            })
            .catch((error) => {
                console.log(error);
                this.plemsg = "Error initiating transaction for payment..."
            });
            this.confrimed = true;
        },
        changePlanNow() {
                    var api = process.env.VUE_APP_API;
                    console.log("Attempting to change plan for: " + this.email + " via " + api)
                    this.loading = true;
                    
                    if (this.payment != "") {
                        this.plan = this.payment;
                    }
                    
                    if (this.plan && api) {
                        this.$store.dispatch('auth/change_plan', this.plan).then(
                        (response) => {
                                console.log("if-first: ");
                                console.log(response);
                                console.log(response.status);
                                console.log(response.message);
                                if (response.data.status == true) {
                                    this.payment = false;
                                    this.$router.push({path: "/dashboard/redirect/profile/paid"});
                                } else {
                                    this.plemsg = "Error: " + response.data.message;
                                    this.$router.push({path: "/dashboard/redirect/profile/failed"});
                                }
                        },
                        error => {
                            console.log(error);
                            this.$router.push({path: "/dashboard/redirect/profile/failed"});
                        });
                    }

                    this.loading = false;
        },
        load() {
                let loader = this.$loading.show({
                container: this.fullPage,
                onCancel: this.onCancel,
                active: this.isLoading
                });
                setTimeout(() => {
                loader.hide()
                },1000)
        },
        onCancel() {
            console.log('User cancelled the loader.')
        },
    }    
}
</script>
<style lang='scss' scoped>
$main-color: #aac734;
h1 {
    font-weight: bold;
}
pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.dash-container {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 60px;
}
.field-table {
    margin-bottom: 10px;
    width: 100%;
}
.field-table th,td {
    padding-left: 5%;
    padding-right: 5%;
}
.pass th, td {
    padding-left: 2%;
    padding-right: 2%;
}
.mail-table {
    margin-left: 0px;
}
.last-ft {
    margin-bottom: 20px;
}
.disclaimer {
    font-size: 0.78rem;
    font-weight: 400;
}
.fa-check {
    color: $main-color;
}
.is-success {
    color: $main-color;
}
.select {
    color: $main-color;
}
.select.is-success select {
    color: $main-color;
    border-color: $main-color;
}
.select.is-success select:hover {
    border-color: $main-color;
}
.select.is-success:not(:hover)::after {
    border-color: $main-color;
}
.select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: $main-color;
}
.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
    box-shadow: rgba(170, 199, 52, 0.25);
}
.is-success.input {
    color: $main-color;
    border-color: $main-color;
}
.has-text-success {
    color: $main-color;
}
.submit {
    margin-top: 20px;
    text-align: center;
    width: 300px;
}
.change {
    text-align: center;
}
.profile-plan {
    width: 25%;
}
.profile-form {
    width: 100%;
    // box-shadow: 0px 2px 30px rgba(0,0,0,0.2);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding-right: 10px;
}
.profile-header {
    font-weight: bold;
    padding: 20px;
    background: #cccccc //$main-color;
}
.profile-content {
    background: #F5F5F5;
    padding: 30px;
    padding-left: 15%;
    padding-right: 15%;
}
b-field.label {
    font-size: 0.78rem;
    text-align: left;
    padding-left: 5px;
    font-weight: 400;
    max-width: 600px;
}
.btn-msg {
    font-size: 0.78rem;
    padding-top: 10px;
    text-align: left;
}
.done {
    color: $main-color;
}
.failed {
    color: red;
}
.small-print {
    font-size: 0.80rem;
    padding-top: 0px;
    padding-bottom: 10px;
}
.small-print-end {
    font-size: 0.80rem;
    padding-bottom: 0px;
}
.disclaimer {
    width: 100%;
    text-align: left;
    padding-left: 15px;
}
.profile-price-table {
    border: 1px dashed #CCCCCC;
    width: 100%;
    margin-bottom: 10px;
}
.ppt-header {
    padding: 10px;
    background-color: $main-color;
}
.ppt-price {
    padding: 10px;
    margin-bottom: 0px;
}
.plan-table {
    box-shadow: 5px 10px 15px $main-color;
}
.change-instruct {
    padding: 5px;
    padding-left: 5px;
}
.filtered {
    filter: brightness(70%);
}
.vip {
    border-radius: 5px;
    border: 0px;
    background-color: $main-color;
    margin-left: 10px; 
}
</style>